<template>
    <main role="main">
        <div class="container text-center">

            <div class="card auth-card">

                <div class="card-body">

                    <div class="header">
                        <img src="../assets/img/logos/logo-color.svg" width="30" height="30" alt="">
                        <h1 class="h3 mb-3">
                            {{ env.VUE_APP_NAME }}
                        </h1>
                    </div>

                    <div v-if="ctrl.message" class="alert alert-danger" role="alert">
                        {{ ctrl.message }}
                    </div>

                    <div class="mb-3">
                        <template v-if="ctrl.status ==='success'">
                            <p>
                                <router-link :to="{ name: 'sign-in'}">Retornar</router-link>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
main {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 350px;
    min-height: 406px;
}

main .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.header {
    margin: 20px 0 40px 0;
}

.header img {
    width: 100px;
    height: 100px;
}

.header h1 {
    margin: 15px 0;
    color: #444;
    font-weight: 500;
}

.auth-card {
    width: 100%;
    max-width: 430px;
}
</style>

<script>
import AuthMixin from "../mixins/AuthMixin";

export default {
    mixins: [AuthMixin],
    data() {
        return {
            ctrl: {
                message: 'Saindo do sistema...'
            }
        }
    },
    created() {
        this._common_logoutUser()
            .then(() => {
                this.ctrl.status = 'success';
                this.ctrl.message = 'Você saiu do sistema';
            })
            .catch((error) => {
                this.ctrl.status = 'error';
                this.ctrl.message = error.message || 'Erro';
            });
    }
}
</script>

